@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@300;400;500;700&display=swap');

* {
  font-family: 'Sarabun', sans-serif !important;
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.ant-input-disabled, .ant-input[disabled] {
  color: rgb(124 131 137) !important;
}

.upload-container {
  position: absolute;
  left: 0;
  width: 100%;
  top: 50px
}

.upload-container .ant-upload.ant-upload-select-text {
  float: right;
  margin-top: -40px;
  margin-right: 2em;
}

.upload-container .ant-upload-list {
  margin-top: 10px;
  width: max-content;
}


/* งานหยาบบบบ */
div[data-label="ไม่สามารถระบุสถานที่เกิดเหตุได้?"],
div[data-label="สถานที่สาธารณะ?"],
div[data-label="เหตุการณ์ยังไม่สิ้นสุด"] {
  flex-direction: row-reverse !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

div[data-label="สถานที่สาธารณะ?"],
div[data-label="เหตุการณ์ยังไม่สิ้นสุด"] {
  margin-top: 1.8em;
}

div[data-label="ไม่สามารถระบุสถานที่เกิดเหตุได้?"] .ant-form-item-label, 
div[data-label="ไม่สามารถระบุสถานที่เกิดเหตุได้?"] .ant-form-item-control,
div[data-label="สถานที่สาธารณะ?"] .ant-form-item-label, 
div[data-label="สถานที่สาธารณะ?"] .ant-form-item-control,
div[data-label="เหตุการณ์ยังไม่สิ้นสุด"] .ant-form-item-label, 
div[data-label="เหตุการณ์ยังไม่สิ้นสุด"] .ant-form-item-control {
  flex: unset !important;
  padding: 0;
}

.notification-message > .title {
  color: #fff !important;
}